import React, { useState, useEffect, useContext } from "react";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import LoginService from "../../services/LoginService";
// import "./login.scss";
import $ from "jquery";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { UserContext } from "../../routing/Routing";
import HandleMessage from "../../components/HandleMessage/HandleMessages";
import Loader from "../../components/loader/Loader";

export default function Login() {
  // Declare google as a global variable
  /* global google */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
  });

  const { setLoginStatus } = useContext(UserContext);
  const [confirmBox, setConfirmBox] = useState(false);
  const [message, setMessage] = useState({ msg: "", status: false })
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    var headerHeight = $(".navbar").height();
    var footerHeight = $(".footer").height();
    var totalHeight = headerHeight + footerHeight;
    $(".login").css("min-height", "calc(99vh - " + totalHeight + "px - 55px");
    // $(".container").css("min-height", `${totalHeight}`);
    // $(".container").css("background-color", "gray");
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, []);
  $(".d-ipad .nav-item a").removeClass("text-white");


  const handleGoogleLoginClick = () => {
    const googleSignInClient = google.accounts.oauth2.initTokenClient({
      client_id: "578624956220-jnnqqc5gumu4br2ebf45le816mecessg.apps.googleusercontent.com",
      scope:
        'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
      callback: async (tokenResponse) => {
        setLoader(true);
        const accessToken = tokenResponse.access_token;
        await googleLogin(accessToken);
        setLoader(false);
      },
    });
    googleSignInClient.requestAccessToken();
  };

  const googleLogin = (token) => {
    setLoader(true);
    LoginService.postGoogleLogin(token)
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem("token", res.data.token);
          setLoginStatus(true);
          const decoded = jwt_decode(res.data.token);
          if (decoded) {
            if (decoded.role === "Trainer") {
              navigate("/admin/dashboard");
              return;
            } else if (decoded.role === "Admin") {
              navigate("/admin/dashboard");
              return;
            }
          }
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(Object.values(error));
        handleCatchError(error)
      });
    {
      setTimeout(() => setConfirmBox(false), 5000);
    }
  }

  const handleMessage = (message, status, magtime = false) => {
    setConfirmBox(true)
    setMessage({ msg: message, status: status })
    if (magtime) {
      setTimeout(() => setConfirmBox(false), 3000)
    }
  }

  const handleCatchError = (err) => {
    setLoader(false)
    if (err["response"]) {
      if (err["response"].status === 401) {
        if (err["response"]?.data?.message === "You are not authorized to view this content") {
          handleMessage(err["response"].data.message, false)
        } else {
          localStorage.removeItem("token");
          navigate("/login");
        }
      } else if (err["response"].status === 404) {
        handleMessage(err["response"]?.data?.message, false, true)
      } else {
        localStorage.removeItem("token");
        navigate("/login");
      }
    } else {
      handleMessage('Contact site admin for restart server', false)
    }
  }

  // const handleGoogleLogin = () => {
  //   const googleSignInClient = google.accounts.oauth2.initTokenClient({
  //     client_id: CONFIG.googleClientId,
  //     scope:
  //       'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
  //     callback: async (tokenResponse: any) => {
  //       setLoading(true);
  //       const accessToken = tokenResponse.access_token;
  //       await login(accessToken, 'google', '');
  //       setLoading(false);
  //     },
  //   });
  //   googleSignInClient.requestAccessToken();
  // };

  const handleLogin = (data) => {
    let email = data.uname;
    let password = data.pword;
    setLoader(true);
    LoginService.postLogin(email, password)
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem("token", res.data.token);
          setLoginStatus(true);
          const decoded = jwt_decode(res.data.token);
          if (decoded) {
            if (decoded.role === "Trainer") {
              navigate("/admin/dashboard");
              return;
            } else if (decoded.role === "Admin") {
              navigate("/admin/dashboard");
              return;
            }
          }
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(Object.values(error));
        handleCatchError(error)
      });
    {
      setTimeout(() => setConfirmBox(false), 5000);
    }
  };

  return (
    <>
      <section className="container login">
        <div className="row m-0">

          {loader &&
            <Loader loaderStatus={loader} message="Loading ....." />
          }

          <div className="col-lg-5 col-md-7 col-sm-10 mx-auto bg-white py-lg-5 py-3 formcard">
            {confirmBox && <HandleMessage message={message} />}
            <h2 className="text-center heading font-weight-300">Log In</h2>
            <p className="text-center py-1 d-none d-lg-block">
              <span className="text-primary"> Welcome! </span> please login with
              your credentials
            </p>
            <form
              onSubmit={handleSubmit(handleLogin)}
              className="px-lg-4 py-4 px-2"
              method="post"
            >
              <div className="mb-3">
                <label htmlFor="uname" className="form-label color-dback">
                  Email <sup class="text-danger">*</sup>
                </label>
                <input
                  name="email"
                  id="uname"
                  {...register("uname")}
                  placeholder="Enter Email"
                  className="form-control"
                />
                <small className="form-text d-block my-2 text-red">
                  {errors.uname?.message}
                </small>
              </div>
              <div className="mb-3">
                <label htmlFor="upass" className="form-label color-dback">
                  Password <sup class="text-danger">*</sup>
                </label>
                <input
                  type="password"
                  name="password"
                  id="upass"
                  {...register("pword")}
                  placeholder="Enter Password"
                  className="form-control"
                  autoComplete="false"
                />
                <small className="form-text d-block my-2 text-red">
                  {errors.pword?.message}
                </small>
              </div>
              <div className="row mb-3 mx-0">
                <div className="col d-flex pe-0 justify-content-end">
                  <Link to="/forgot-password" className="text-primary">
                    Forgot password?
                  </Link>
                </div>
              </div>
              <div className="footer text-center pt-lg-4 pt-3">
                <button type="button" onClick={() => handleGoogleLoginClick()} className="btn btn-blue">
                  Google Login
                </button>
              </div>
              <div className="footer text-center pt-lg-4 pt-3">
                <button type="submit" className="btn btn-blue">
                  Login
                </button>
              </div>
            </form>
            <div className="d-flex justify-content-center">
              <p className="m-0 p-0 mt-lg-2 mt-1">
                Not Have an account?
                <a
                  title="Signup"
                  href="/signup"
                  className={`mx-2 font-16 text-primary font-weight-400 ${window.screen.width > 992 ? "lead" : "h6"
                    }`}
                >
                  Sign up
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
}
